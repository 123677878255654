<template>
  <div>
    <ToastList />
    <HeaderExburst />
      <DeckbuilderFrame />
    <FooterExburst />
    <div class="loading-overlay" v-if="isLoading">
      <div class="loading-content">
        <span class="loading loading-dots loading-lg"></span>
        <p>{{ loadingMessage }}</p>
      </div>
    </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue/dist/vue.esm-bundler.js';
import { mapState } from 'vuex';

export default {
  components: {
    DeckbuilderFrame: defineAsyncComponent(() => import('../deckbuilder/shared-components/Frames/Main-Frame.vue')),
    ToastList: defineAsyncComponent(() => import('../common/shared-components/ToastList.vue')),
    HeaderExburst: defineAsyncComponent(() => import('../common/shared-components/ExburstHeader.vue')),
    FooterExburst: defineAsyncComponent(() => import('../common/shared-components/ExburstFooter.vue'))
  },
  computed: {
    ...mapState(['isLoading', 'loadingMessage']),
    currentPath() {
      return this.$route.path;
    },
    currentName() {
      return this.$route.name;
    },
    extendedNavButtons() {
      let buttons = [...this.navButtons];
      if (this.isLoggedIn) {
        buttons.push(
          {
            link: '/mydecks',
            label: 'My Decks',
            icon: 'fa-book',
          },
          {
            link: '/myprofile',
            label: 'My Profile',
            icon: 'fa-user',
          }
        );
      }
      return buttons;
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's above other content */
}

.loading-content {
  text-align: center;
  color: white;
}
</style>
